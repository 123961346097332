import styled from 'styled-components'

const Wrapper = styled.div`
  height: calc(100vh - 4rem);
  margin-top: 4rem;
`

const FullHeightWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default FullHeightWrapper
