import { Download, Trash } from 'lucide-react'
import { Item } from '../../../../../types/FileSystem'
import { ContextOption } from './ContextMenuElement'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useRemoveFile from './hooks/useRemoveFile'
import useDownloadFile from './hooks/useDownloadFile'

const useGetFileOptions = (): ((item: Item) => ContextOption[]) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const removeFile = useRemoveFile()
  const downloadFile = useDownloadFile()

  return (item: Item) => {
    return [
      {
        Icon: Trash,
        name: t.fileSystem.itemTable.contextMenu.file.remove.cta,
        confirm: t.fileSystem.itemTable.contextMenu.file.remove.confirm(item),
        onClick: removeFile(item),
        permission: 'deleteFiles',
      },
      {
        Icon: Download,
        name: t.fileSystem.itemTable.contextMenu.file.download.cta,
        onClick: downloadFile(item),
      },
    ]
  }
}

export default useGetFileOptions
