import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import Container from '../../Container/Container'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-white">
      <div className="border-t border-blue py-5">
        <Container>
          <div className="flex flex-col md:flex-row gap-5 md:gap-20">
            <div className="md:w-2/6 shrink-0 text-lg font-bold text-blue">{t.footer.links}</div>
            <div className="md:w-4/6 text-lg flex flex-col md:flex-row gap-0 md:gap-10 md:items-center">
              {t.footer.legalLinks.map((link: { name: string; link: string }, i: number) => (
                <a href={link.link} className="text-lg font-thin text-blue" key={i} target="_blank" rel="noreferrer">
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </Container>
      </div>
      <div className="border-t border-blue py-5">
        <Container>
          <div className="text-lg font-thin text-blue">
            &copy;{` ${new Date().getFullYear()} `}
            {t.generic.company.name}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Footer
