import { Company } from '../../../../types/Company'
import useMemberContext from '../../../hooks/useContext/useMemberContext'
import useFileSystem from '../useFileSystem'

const CompanySelection = () => {
  const [context] = useMemberContext()
  const { fileSystem } = useFileSystem()

  const isActive = (company: Company) => fileSystem.activeCompanyUUID === company.uuid

  if (!context || !fileSystem) return null
  return (
    <div className="px-4">
      {context.companies.map((company) => (
        <div
          key={company.uuid}
          className={`cursor-pointer p-3 text-md w-full justify-start border-b border-white ${
            isActive(company) ? 'bg-blue text-white font-bold' : 'bg-transparent text-blue'
          }`}
        >
          {company.name}
        </div>
      ))}
    </div>
  )
}

export default CompanySelection
