import { Trash } from 'lucide-react'
import { Item } from '../../../../../types/FileSystem'
import { ContextOption } from './ContextMenuElement'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useRemoveFolder from './hooks/useRemoveFolder'

const useFolderOptions = (): ((item: Item) => ContextOption[]) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const removeFolder = useRemoveFolder()

  return (item: Item) => {
    return [
      {
        Icon: Trash,
        name: t.fileSystem.itemTable.contextMenu.folder.remove.cta,
        confirm: t.fileSystem.itemTable.contextMenu.folder.remove.confirm(item),
        onClick: removeFolder(item),
        permission: 'deleteFolders',
      },
    ]
  }
}

export default useFolderOptions
