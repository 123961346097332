import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { FileSystem, FileSystemStatus, Item } from '../../../types/FileSystem'
import { useCallback } from 'react'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import useMemberContext from '../../hooks/useContext/useMemberContext'
import { useNavigate } from 'react-router-dom'

type ChangeDirectory = (prefix: string) => void
type AddItems = (items: Item[]) => void
type RemoveItem = (item: Item) => void
type Init = (activePrefix: string) => void

type Response = {
  fileSystem: FileSystem
  changeDirectory: ChangeDirectory
  addItems: AddItems
  removeItem: RemoveItem
  init: Init
}

const useFileSystem = (): Response => {
  const [memberContext] = useMemberContext()
  const listItems = functional.use(Routes.FILE_SYSTEM_LIST_ITEMS)
  const fileSystem: FileSystem = useSelector((s) => s[props.FILE_SYSTEM])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchFiles = useCallback(
    async (fileSystem: FileSystem) => {
      const items = await listItems({ prefix: fileSystem.activePrefix, companyUUID: fileSystem.activeCompanyUUID })
      const payload: FileSystem = { ...fileSystem, items, status: FileSystemStatus.READY }
      dispatch(setLocal(props.FILE_SYSTEM, payload))
      navigate(`/dashboard/file-explorer/${encodeURIComponent(fileSystem.activePrefix)}`)
    },
    [dispatch, listItems, navigate],
  )

  const init: Init = (activePrefix: string) => {
    if (!!memberContext && memberContext.companies.length > 0) {
      const fileSystemPayload: FileSystem = {
        activeCompanyUUID: memberContext.companies[0].uuid,
        activePrefix,
        items: null,
        status: FileSystemStatus.READY,
      }
      fetchFiles(fileSystemPayload)
    }
  }

  const changeDirectory: ChangeDirectory = (prefix) => {
    fetchFiles({
      ...fileSystem,
      activePrefix: prefix,
    })
  }

  const addItems: AddItems = (items) => {
    const updatedItems = [...fileSystem.items]
    for (const item of items) {
      const index = updatedItems.findIndex((i) => i.key === item.key)
      if (index === -1) {
        updatedItems.push(item)
      } else {
        updatedItems[index] = item
      }
    }
    const payload: FileSystem = { ...fileSystem, items: updatedItems }
    dispatch(setLocal(props.FILE_SYSTEM, payload))
  }

  const removeItem: RemoveItem = (item) => {
    const items = [...fileSystem.items.filter((i) => i.key !== item.key)]
    const payload: FileSystem = { ...fileSystem, items }
    dispatch(setLocal(props.FILE_SYSTEM, payload))
  }

  return { fileSystem, changeDirectory, addItems, removeItem, init }
}

export default useFileSystem
