import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  // generic features
  MEMBER: 'MEMBER',
  ACCESS: 'ACCESS',
  PDF_INTERPRETATION: 'PDF_INTERPRETATION',
  COMPANY: 'COMPANY',
  MEMBER_CONTEXT: 'MEMBER_CONTEXT',

  // file explorer features
  FILE_SYSTEM: 'FILE_SYSTEM',
}

export default props
