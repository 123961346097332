export enum FileSystemStatus {
  PENDING = 'PENDING',
  RESOLVE_DIRECTORY = 'RESOLVE_DIRECTORY',
  READY = 'READY',
}

export enum ItemType {
  FILE = 'FILE',
  FOLDER = 'FOLDER',
}

export type File = {
  uuid?: string
  eTag: string
  type: ItemType.FILE
  name: string
  prefix: string
  key: string
  size: number
  createdDate: Date
  updatedDate: Date
}

export type Folder = {
  uuid?: string
  type: ItemType.FOLDER
  name: string
  prefix: string
  key: string
  // size (number of included items), createdDate and updateDate is dynamic
  size?: number
  createdOn?: Date
  updatedOn?: Date
}

export type Item = File | Folder

export type FileSystem = {
  status: FileSystemStatus
  activeCompanyUUID?: string
  activePrefix?: string
  items?: Array<Item>
}
