import { Link } from 'react-router-dom'
import { NavigationItem } from '../../../types'
import HasPermission from '../../../../../../Dashboard/HasPermission/HasPermission'

type Props = {
  data: NavigationItem
  className?: string
  onClick?: () => void
  mobile?: boolean
}

const Default: React.FC<Props> = ({ data, className = '', onClick = null }) => {
  const clicked = () => {
    if (onClick) {
      onClick()
    }
  }

  const Item = (
    <div className={`flex flex-row gap-1 items-center`} onClick={clicked}>
      <Link to={data.link || ''} className={`lg:text-white no-underline hover:underline hover:bg-opacity-20 py-1 lg:px-3 rounded-full ${className}`}>
        {data.name}
      </Link>
    </div>
  )

  if (data.requiredPermission) return <HasPermission permission={data.requiredPermission}>{Item}</HasPermission>
  return Item
}

export default Default
