import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import logo from '../../../../assets/image/logo/logo.png'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

type Props = { hideNavigation?: boolean; disableDynamicHeight?: boolean; small?: boolean }

const Header: React.FC<Props> = ({ hideNavigation = false, disableDynamicHeight = false, small = false }) => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink
  const t = useSelector((s) => s[props.TRANSLATION])

  const goHome = () => {
    let path = '/'
    if (isLoggedIn()) {
      path = '/dashboard'
    }
    navigate(path)
  }

  return (
    <div className={`z-20 h-16 transition-all top-0 left-0 fixed w-full shadow-md flex flex-row items-center justify-between px-3`}>
      <div className={`absolute top-0 left-0 w-full h-full -z-10 bg-blue`}></div>
      <div onClick={goHome} className={`cursor-pointer ${!accountLink && 'mx-auto'}`}>
        <img src={logo} alt={t.projectName} className={'h-11'} />
      </div>
      {!hideNavigation && <Navigation />}
    </div>
  )
}

export default Header
