import { crud } from '@think-internet/zeus-frontend-package'
import { TrashIcon } from '@heroicons/react/24/outline'
import Modal from '../../../../UI/Modal/Modal'
import props from '../../../../../redux/props'
import useToast, { Type } from '../../../../hooks/useToast'
import { useState } from 'react'
import { Company } from '../../../../../types/Company'

const { useSelector } = require('react-redux')

type Props = { company: Company; callback: () => void }

const Remove: React.FC<Props> = ({ company, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const companyFeature = crud.use(props.COMPANY)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    const result = await companyFeature.remove({ uuid: company.uuid })
    if (result) {
      callback()
    } else {
      toast(t.settings.companyManagement.upsert.remove.error, Type.ERROR)
    }
  }

  return (
    <>
      <TrashIcon className="h-5 stroke-red cursor-pointer" onClick={toggle} />
      <Modal onConfirm={confirm} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{t.settings.companyManagement.upsert.remove.title}</div>
        <div className="mt-3">{t.settings.companyManagement.upsert.remove.hint}</div>
      </Modal>
    </>
  )
}

export default Remove
