import { Item } from '../types/FileSystem'

export const translation = {
  projectName: 'LIGNE - L.I.S.A.',

  header: {
    navigation: {
      default: [
        {
          name: 'Anmelden',
          link: '/',
        },
      ],
      member: [
        {
          name: 'Dateien',
          link: '/dashboard/file-explorer',
        },
        {
          name: 'Einstellungen',
          link: '/dashboard/settings',
          requiredPermission: 'settingsAccess',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  dashboard: {
    title: 'Dashboard',
  },

  fileSystem: {
    sidebar: {
      companySelection: {
        title: 'Unternehmen',
      },
    },
    itemTable: {
      header: {
        name: 'Name',
        size: 'Größe',
        updatedDate: 'Geändert am',
      },
      folderSizeSuffixSingle: ' Element',
      folderSizeSuffixMultiple: ' Elemente',
      contextMenu: {
        confirm: {
          title: 'Bestätigung',
        },
        folder: {
          remove: {
            cta: 'Löschen',
            confirm: (item: Item) => `Möchten Sie den Ordner "${item.name}" wirklich löschen?`,
            success: 'Ordner wurde erfolgreich gelöscht',
            error: 'Ordner konnte nicht gelöscht werden',
          },
        },
        file: {
          remove: {
            cta: 'Löschen',
            confirm: (item: Item) => `Möchten Sie die Datei "${item.name}" wirklich löschen?`,
            success: 'Datei wurde erfolgreich gelöscht',
            error: 'Datei konnte nicht gelöscht werden',
          },
          download: {
            cta: 'Herunterladen',
            error: 'Datei konnte nicht heruntergeladen werden',
          },
        },
      },
    },
    toolbar: {
      runInitialization: {
        cta: 'Initialisieren',
        success: 'Initialisierung erfolgreich',
        error: 'Initialisierung fehlgeschlagen',
      },
      createFolder: {
        title: 'Ordner anlegen',
        placeholder: 'Name des Ordners',
        cta: 'Ordner anlegen',
        success: 'Ordner erstellt',
        error: 'Ordner konnte nicht erstellt werden',
      },
      uploadFile: {
        title: 'Dateien hochladen',
        cta: 'Dateien hochladen',
        success: 'Dateien wurden erfolgreich hochgeladen',
        error: 'Dateien konnten nicht hochgeladen werden',
      },
    },
  },

  pdfInterpreter: {
    upload: {
      label: 'PDF hochladen',
    },
  },

  login: {
    headline: 'Anmelden',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  settings: {
    title: 'Einstellungen',
    memberManagement: {
      title: 'Mitarbeiter',
      upsert: {
        titleCreate: 'Mitarbeiter hinzufügen',
        titleUpdate: 'Mitarbeiter bearbeiten',
        cta: 'Mitarbeiter hinzufügen',
        success: 'Mitarbeiter wurde erfolgreich gespeichert',
        error: {
          upsert: 'Mitarbeiter konnte nicht gespeichert werden',
          remove: 'Mitarbeiter konnte nicht gelöscht werden',
          loading: 'Mitarbeiter konnten nicht geladen werden',
        },
        edit: 'Mitarbeiter bearbeiten',
        remove: {
          title: 'Mitarbeiter löschen',
          hint: 'Sind Sie sicher, dass Sie diesen Mitarbeiter löschen möchten?',
          error: 'Mitarbeiter konnte nicht gelöscht werden',
        },
      },
      companyAssignment: {
        title: 'Unternehmenszuweisung',
      },
    },
    accessManagement: {
      title: 'Berechtigungen',
      update: {
        success: 'Berechtigung wurde erfolgreich gespeichert',
        cta: 'Speichern',
        error: {
          upsert: 'Berechtigung konnte nicht gespeichert werden',
          loading: 'Berechtigungen konnten nicht geladen werden',
        },
      },
      level: {
        title: 'Berechtigung',
        ADMIN: 'Geschäftsführung',
        ADVANCED: 'Office',
        USER: 'Benutzer',
      },
      properties: {
        uploadFiles: 'Dateien hochladen',
        deleteFiles: 'Dateien löschen',
        createFolders: 'Ordner erstellen',
        deleteFolders: 'Ordner löschen',
        shareFiles: 'Dateien teilen',
        shareFolders: 'Ordner teilen',
        settingsAccess: 'L.I.S.A. Einstellungen',
      },
    },
    companyManagement: {
      title: 'Unternehmen',
      upsert: {
        titleCreate: 'Unternehmen hinzufügen',
        titleUpdate: 'Unternehmen bearbeiten',
        cta: 'Unternehmen hinzufügen',
        success: 'Unternehmen wurde erfolgreich gespeichert',
        error: {
          upsert: 'Unternehmen konnte nicht gespeichert werden',
          remove: 'Unternehmen konnte nicht gelöscht werden',
          loading: 'Unternehmen konnten nicht geladen werden',
        },
        edit: 'Unternehmen bearbeiten',
        remove: {
          title: 'Unternehmen löschen',
          hint: 'Sind Sie sicher, dass Sie diesen Unternehmen löschen möchten?',
          error: 'Unternehmen konnte nicht gelöscht werden',
        },
      },
    },
  },

  footer: {
    links: 'Links',
    legalLinks: [
      { name: 'Impressum', link: 'https://www.ligne.de/impressum' },
      { name: 'Datenschutz', link: 'https://www.ligne.de/datenschutz' },
    ],
  },

  generic: {
    company: {
      name: 'LIGNE ARCHITEKTEN',
      street: 'Wilhelm-Kabus-Str. 36, Haus 9',
      city: '10829 Berlin',
      mail: 'info@ligne.de',
    },
    settings: 'Einstellungen',
    hourSuffix: ' Stunden',
    squareMeterSuffix: ' m²',
    equals: ' = ',
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    personType: 'Art',
    personTypePlaceholder: 'Beirat, Geschäftsführer, ...',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    save: 'Speichern',
  },

  UI: {
    removeConfirmation: {
      title: 'Bestätigung',
      text: (itemName: string) => `Möchten Sie "${itemName}" wirklich löschen?`,
    },
    copyConfirmation: {
      title: 'Kopieren',
      text: (itemName: string) => `${itemName}-Kopie umbenennen zu:`,
      initNameSuffix: '-Kopie',
    },
    customerSelector: {
      label: 'Kundenauswahl',
    },
    projectSelector: {
      label: 'Projektauswahl',
    },
    contactPersonManager: {
      title: 'Kontaktpersonen',
      addTitle: 'Kontaktperson hinzufügen',
      updateTitle: 'Kontaktperson bearbeiten',
      empty: 'Keine Kontaktpersonen vorhanden',
      add: 'hinzufügen',
    },
    organizationUnitManager: {
      title: 'Organisationseinheiten',
      addTitle: 'Organisationseinheit hinzufügen',
      employeeAmountSuffixShort: ' MA',
      employeeAmountSuffix: ' Mitarbeiter',
      updateTitle: 'Organisationseinheit bearbeiten',
      empty: 'Keine Organisationseinheiten vorhanden',
      add: 'hinzufügen',
    },
    simpleList: {
      search: 'Suche',
      resultAmountSuffixPlural: ' Ergebnisse',
      resultAmountSuffixSingular: ' Ergebnis',
      noData: 'Keine Daten vorhanden',
    },
    multiList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
      cta: 'Ausführen',
      cancelCTA: 'Abbrechen',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Verarbeiten',
        STOP: 'Stoppen',
      },
      export: {
        title: 'Export',
        identifier: 'Kennung',
      },
      error: 'Aktion konnte nicht ausgeführt werden',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Maximale Dateigröße überschritten',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
