import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import FileSystem from './FileSystem/FileSystem'
import Settings from './Settings/Settings'

const Dashboard = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN, Level.ADVANCED, Level.USER]} noFooter>
      <Routes>
        {/* default routes */}
        <Route path={'/file-explorer'} element={<FileSystem />} />
        <Route path={'/file-explorer/:path'} element={<FileSystem />} />
        <Route path={'/settings'} element={<Settings />} />

        {/* fallback route */}
        <Route path={'*'} element={<Navigate replace to="/dashboard/file-explorer" />} />
      </Routes>
    </Layout>
  )
}

export default Dashboard
