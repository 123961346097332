import { translation, langKey } from '../translations/de'
import { FileSystem, FileSystemStatus } from '../types/FileSystem'

export type DefaultState = {
  TRANSLATION: { [key: string]: any }
  CURRENTLANG: String
  FILE_SYSTEM: FileSystem
}

const defaultState: DefaultState = {
  TRANSLATION: translation,
  CURRENTLANG: langKey,
  FILE_SYSTEM: {
    status: FileSystemStatus.PENDING,
  },
}

export default defaultState
