import { Access } from '../../../types/Access'
import useMemberContext from '../../hooks/useContext/useMemberContext'

type Props = {
  children: React.ReactNode
  permission: keyof Access
}

const HasPermission: React.FC<Props> = ({ children, permission }) => {
  const [context] = useMemberContext()

  const hasPermission = () => context?.permissions[permission]

  if (!context || !hasPermission()) return <></>
  return <>{children}</>
}

export default HasPermission
