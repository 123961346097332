import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import Accordion from '../../UI/Accordion/Accordion'
import MemberManagement from './MemberManagement/MemberManagement'
import AccessManagement from './AccessManagement/AccessManagement'
import FullHeightWrapper from '../../UI/FullHeightWrapper/FullHeightWrapper'
import CompanyManagement from './CompanyManagement/CompanyManagement'

const Settings = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const items = [
    { key: t.settings.memberManagement.title, title: t.settings.memberManagement.title, content: <MemberManagement /> },
    { key: t.settings.accessManagement.title, title: t.settings.accessManagement.title, content: <AccessManagement /> },
    { key: t.settings.companyManagement.title, title: t.settings.companyManagement.title, content: <CompanyManagement /> },
  ]

  return (
    <FullHeightWrapper>
      <Container>
        <div className="flex flex-col gap-1 py-10">
          <div className="font-bold text-lg text-blue">{t.settings.title}</div>
          <Accordion className="mt-3" items={items} />
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Settings
