import { useState } from 'react'
import Section from './Section/Section'
import _ from 'lodash'

type Props = {
  items: {
    key: string
    title: React.ReactNode | string
    content: React.ReactNode | string
  }[]
  className?: string
  initOpenIndex?: string
}

const Accordion: React.FC<Props> = ({ items, className = '', initOpenIndex }) => {
  const [openIndex, setOpenIndex] = useState<string>(!_.isUndefined(initOpenIndex) ? initOpenIndex : null)

  const toggle = (index: string) => () => setOpenIndex(openIndex === index ? null : index)

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      {items.map(({ title, content, key }) => (
        <Section key={key} open={openIndex === key} title={title} clickedCallback={toggle(key)}>
          {content}
        </Section>
      ))}
    </div>
  )
}

export default Accordion
