import { useSelector } from 'react-redux'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import props from '../../../../redux/props'
import useCompanies from '../../../hooks/useContext/useCompany'

type Props = {
  value: string[]
  onChange: (value: string[]) => void
}

const CompanyAssignment: React.FC<Props> = ({ value, onChange }) => {
  const [companies] = useCompanies()
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!companies) return null
  return (
    <Dropdown
      label={t.settings.memberManagement.companyAssignment.title}
      required
      items={companies.map((c) => ({ label: c.name, value: c.uuid }))}
      value={value}
      onChange={onChange}
      multiple
    />
  )
}

export default CompanyAssignment
