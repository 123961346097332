import { useState } from 'react'
import Button from '../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Modal from '../../../UI/Modal/Modal'
import Input from '../../../UI/Input/Input'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import { PencilIcon } from '@heroicons/react/24/solid'
import Remove from './Remove/Remove'
import { Company } from '../../../../types/Company'

type Props = {
  company?: Company
  pullList: () => void
}

const Upsert: React.FC<Props> = ({ company = {}, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<Company>(company as Company)
  const companyFeature = crud.use(props.COMPANY)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const save = async () => {
    const result: Company = await companyFeature.upsert(data)
    if (!!result) {
      toast(t.settings.companyManagement.upsert.success, Type.SUCCESS)
      pullList()
      toggle()
    } else {
      toast(t.settings.companyManagement.upsert.error.upsert, Type.ERROR)
    }
  }

  const set = (key: string) => (value: string) => setData({ ...data, [key]: value })

  if (!data) return null
  return (
    <div>
      {data.uuid ? (
        <div className="flex flex-row items-center justify-between">
          <div>{data.name}</div>
          <div className="flex flex-row gap-3">
            <Remove company={data} callback={pullList} />
            <PencilIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />
          </div>
        </div>
      ) : (
        <Button onClick={toggle} text={t.settings.companyManagement.upsert.cta} />
      )}
      <Modal onConfirm={save} show={open} onClose={toggle}>
        <div className="text-blue font-bold">
          {data?.uuid ? t.settings.companyManagement.upsert.titleUpdate : t.settings.companyManagement.upsert.titleCreate}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <Input required label placeholder={t.generic.name} value={data.name} onChange={set('name')} />
        </div>
      </Modal>
    </div>
  )
}

export default Upsert
