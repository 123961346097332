import Upsert from './Upsert'
import useMember from '../../../hooks/useContext/useMember'

const MemberManagement = () => {
  const [members, pull] = useMember()

  return (
    <div>
      <div className="flex justify-end">
        <Upsert pullList={pull} />
      </div>
      {!!members && (
        <div className="flex flex-col gap-1 mt-3">
          {members.map((member) => (
            <Upsert key={member.uuid} member={member} pullList={pull} />
          ))}
        </div>
      )}
    </div>
  )
}

export default MemberManagement
