import { functional } from '@think-internet/zeus-frontend-package'
import { Item } from '../../../../../../types/FileSystem'
import Routes from '../../../../../../redux/routes'
import useToast, { Type } from '../../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import useFileSystem from '../../../useFileSystem'

const useRemoveFile = () => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const removeFile = functional.use(Routes.FILE_SYSTEM_REMOVE_FILE)
  const { removeItem } = useFileSystem()

  return (item: Item) => async () => {
    const status = await removeFile({ key: item.key })
    if (status) {
      removeItem(item)
      toast(t.fileSystem.itemTable.contextMenu.file.remove.success, Type.SUCCESS)
    } else {
      toast(t.fileSystem.itemTable.contextMenu.file.remove.error)
    }
  }
}

export default useRemoveFile
