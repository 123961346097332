import DefaultActionButton from '../DefaultActionButton'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import useToast, { Type } from '../../../../hooks/useToast'
import { Folder as FolderIcon } from 'lucide-react'
import useFileSystem from '../../useFileSystem'
import { useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'
import Input from '../../../../UI/Input/Input'
import HasPermission from '../../../HasPermission/HasPermission'
import { Folder } from '../../../../../types/FileSystem'

const CreateFolder = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const createFolder = functional.use(Routes.FILE_SYSTEM_CREATE_FOLDER)
  const toast = useToast()
  const { fileSystem, addItems } = useFileSystem()
  const [name, setName] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const run = async () => {
    const data: Folder = await createFolder({ prefix: fileSystem.activePrefix, name })
    if (data) {
      addItems([data])
      setIsOpen(false)
      setName('')
      toast(t.fileSystem.toolbar.createFolder.success, Type.SUCCESS)
    } else {
      toast(t.fileSystem.toolbar.createFolder.error)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  return (
    <HasPermission permission="createFolders">
      <DefaultActionButton onClick={toggle} Icon={FolderIcon} name={t.fileSystem.toolbar.createFolder.cta} />
      <Modal onClose={toggle} show={isOpen} onConfirm={run}>
        <div className="text-blue font-bold">{t.fileSystem.toolbar.createFolder.title}</div>
        <div className="flex flex-col gap-3 mt-3">
          <Input placeholder={t.fileSystem.toolbar.createFolder.placeholder} value={name} onChange={setName} />
        </div>
      </Modal>
    </HasPermission>
  )
}

export default CreateFolder
