import { useCallback, useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import useToast, { Type } from '../../../hooks/useToast'
import { useSelector } from 'react-redux'
import { Access } from '../../../../types/Access'
import Update from './Update'
import Routes from '../../../../redux/routes'

enum Status {
  IDLE,
  LOADING,
  READY,
}

const AccessManagement = () => {
  const [accessList, setAccessList] = useState<Access[]>(null)
  const getAccessList = functional.use(Routes.GET_ACCESS_LIST)
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [status, setStatus] = useState(Status.IDLE)

  const pullAccessList = useCallback(async () => {
    setStatus(Status.LOADING)
    const accessList = await getAccessList({})
    if (Array.isArray(accessList)) {
      setAccessList(accessList)
      setStatus(Status.READY)
    } else {
      toast(t.settings.accessManagement.update.error.loading, Type.ERROR)
    }
  }, [getAccessList, t, toast])

  useEffect(() => {
    if (!accessList && status === Status.IDLE) {
      pullAccessList()
    }
  }, [accessList, pullAccessList, status])

  return (
    <div>
      {!!accessList && (
        <div className="flex flex-col gap-10 mt-3">
          {accessList.map((access) => (
            <Update key={access.uuid} access={access} pullList={pullAccessList} />
          ))}
        </div>
      )}
    </div>
  )
}

export default AccessManagement
