import { useState } from 'react'
import Button from '../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import { Access } from '../../../../types/Access'
import Toggle from '../../../UI/Toggle/Toggle'
import Form from '../../../UI/Form/Form'
import styled from 'styled-components'

type Props = {
  access?: Access
  pullList: () => void
}

const ToggleWrapper = styled.div`
  width: calc(100% / 4 - 20px * 3 / 4);
`

const Update: React.FC<Props> = ({ access = {}, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Access>(access as Access)
  const accessFeature = crud.use(props.ACCESS)
  const toast = useToast()

  const save = async () => {
    const result: Access = await accessFeature.upsert(data)
    if (!!result) {
      toast(t.settings.accessManagement.update.success, Type.SUCCESS)
      pullList()
    } else {
      toast(t.settings.accessManagement.update.error.upsert, Type.ERROR)
    }
  }

  const toggle = (key: string) => (value: boolean) => setData({ ...data, [key]: value })

  if (!data) return null
  return (
    <Form onSubmit={save} className="bg-white p-3 shadow-sm">
      <div className="text-blue font-bold">{t.settings.accessManagement.level[data.level]}</div>
      <div className="flex flex-row flex-wrap gap-[20px] mt-3">
        {Object.keys(t.settings.accessManagement.properties).map((property) => (
          <ToggleWrapper key={property}>
            <Toggle label={t.settings.accessManagement.properties[property]} value={data[property]} onChange={toggle(property)} />
          </ToggleWrapper>
        ))}
      </div>
      <div className="flex justify-end">
        <Button text={t.settings.accessManagement.update.cta} type="submit" />
      </div>
    </Form>
  )
}

export default Update
