import DefaultActionButton from '../DefaultActionButton'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { File } from 'lucide-react'
import { useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'
import HasPermission from '../../../HasPermission/HasPermission'
import DragAndDrop from '../../../../UI/DragAndDrop/DragAndDrop'
import useFileUpload, { FileUploadStatus } from './useFileUpload'
import useFileSystem from '../../useFileSystem'
import Progress from '../../../../UI/Progress/Progress'
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline'

const UploadFile = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [allFiles, setAllFiles] = useState<File[]>([])
  const { fileSystem, addItems } = useFileSystem()
  const { fileUpload, state } = useFileUpload()

  const run = async () => {
    const files = await fileUpload(fileSystem.activePrefix, allFiles)
    addItems(files)
    setAllFiles([])
    toggle()
  }

  const toggle = () => setIsOpen(!isOpen)

  const process = (files: File[]) => {
    setAllFiles([...allFiles, ...files])
  }

  const removeFile = (name: string) => () => {
    setAllFiles(allFiles.filter((file) => file.name !== name))
  }

  const isReady = state.status === FileUploadStatus.READY

  return (
    <HasPermission permission="uploadFiles">
      <DefaultActionButton onClick={toggle} Icon={File} name={t.fileSystem.toolbar.uploadFile.cta} />
      <Modal onClose={toggle} noClose={!isReady} show={isOpen} onConfirm={isReady && run}>
        <div className="text-blue font-bold">{t.fileSystem.toolbar.uploadFile.title}</div>
        {!isReady && (
          <div className="mt-3">
            <div className="text-center text-sm whitespace-nowrap truncate">{`${state.workingOn}`}</div>
            <div className="my-1">
              <Progress progress={state.progress} stayVisible />
            </div>
            <div className="text-center text-sm">{`${state.remaining} / ${state.total}`}</div>
          </div>
        )}
        {isReady && (
          <>
            <DragAndDrop multiple onFiles={process}>
              <div className="mt-3 flex items-center flex-col">
                <CloudArrowUpIcon className="stroke-blue hover:scale-105 transition-all w-20" />
              </div>
            </DragAndDrop>
            {Array.isArray(allFiles) && allFiles.length > 0 && (
              <div className="divide-y mt-3 overflow-hidden w-full">
                {allFiles.map((file) => (
                  <div key={file.name} className="text-sm flex items-center w-full gap-3">
                    <div className="w-full whitespace-nowrap truncate">{file.name}</div>
                    <div className="w-fit cursor-pointer" onClick={removeFile(file.name)}>
                      <TrashIcon className="h-4 stroke-blue" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </Modal>
    </HasPermission>
  )
}

export default UploadFile
