import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import FullHeightWrapper from '../../UI/FullHeightWrapper/FullHeightWrapper'
import CompanySelection from './CompanySelection/CompanySelection'
import Toolbar from './Toolbar/Toolbar'
import Breadcrumbs from './Breadcrumbs/Breadcrumbs'
import useFileSystem from './useFileSystem'
import { FileSystemStatus } from '../../../types/FileSystem'
import ItemTable from './ItemTable/ItemTable'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FILE_SYSTEM_ROOT } from '../../../utility'

export default function FileSystem() {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { fileSystem, init } = useFileSystem()
  const { path } = useParams()

  useEffect(() => {
    const decodedPath = decodeURIComponent(path)

    if (fileSystem.status === FileSystemStatus.PENDING) {
      init(!!path ? decodedPath : FILE_SYSTEM_ROOT)
    } else if (fileSystem.status === FileSystemStatus.READY && fileSystem.activePrefix !== decodedPath) {
      init(!!path ? decodedPath : FILE_SYSTEM_ROOT)
    }
  }, [fileSystem, init, path])

  if (fileSystem.status === FileSystemStatus.PENDING) return null
  return (
    <FullHeightWrapper>
      <div className="flex flex-row gap-1 h-full">
        {/* Sidebar */}
        <div className="w-64 bg-white py-5">
          <h1 className="text-xl font-bold mb-4 px-4">{t.fileSystem.sidebar.companySelection.title}</h1>
          <CompanySelection />
        </div>

        {/* Main content */}
        <div className="flex-1 flex flex-col h-full overflow-hidden p-5">
          {/* Breadcrumb and toolbar */}
          <div className="flex flex-col h-fit">
            <Toolbar />
            <Breadcrumbs />
          </div>

          {/* File list */}
          <ItemTable />
        </div>
      </div>
    </FullHeightWrapper>
  )
}
