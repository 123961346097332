// import RunInitialization from './RunInitialization/RunInitialization'
import CreateFolder from './CreateFolder/CreateFolder'
import UploadFile from './UploadFile/UploadFile'

const Toolbar = () => {
  return (
    <div className="flex gap-5">
      <CreateFolder />
      <UploadFile />
      {/* <RunInitialization /> */}
    </div>
  )
}

export default Toolbar
